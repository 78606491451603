<template>
  <div class="page">
    <div class="top">
      <div class="title">联系我们</div>
      <div class="eng">CONTACT US</div>
    </div>
    <div class="content wow animate__fadeInUp">
      <div class="map">
        <img src="../../assets/images/map.png" class="img" />
        <div class="map-title hvr-pulse">
          <div class="shxr-title">上海信睿</div>
          <i class="el-icon-location outline"></i>
        </div>
      </div>
      <div class="bottom">
        <div class="title">上海总部联系方式</div>
        <div class="line"></div>
        <div class="bottom-cell">
          <div class="list-cell">
            <div class="list">
              <div class="icon" ><img src="../../assets/images/E03-5.png" class="icon-img hvr-pop"/></div>
              <div class="text">
                <div class="title-address">总部地址</div>
                <div class="address">上海市杨浦区昆明路518号北美广场A座905室</div>
              </div>
            </div>
            <div class="list">
              <div class="icon"><img src="../../assets/images/E03-7.png" class="icon-img hvr-pop"/></div>
              <div class="text">
                <div class="title-address">邮箱</div>
                <div class="address">kangm@shxrtech.com</div>
              </div>
            </div>
            <div class="list">
              <div class="icon"><img src="../../assets/images/E03-6.png" class="icon-img hvr-pop"/></div>
              <div class="text">
                <div class="title-address">联系电话</div>
                <div class="address">021-39885096</div>
                <div class="address">15297767757</div>
              </div>
            </div>
            <div class="list">
              <div class="icon"><img src="../../assets/images/E03-8.png" class="icon-img hvr-pop"/></div>
              <div class="text">
                <div class="title-address">官方微信</div>
                <div class="address">公众号上搜索"上海信睿"</div>
              </div>
            </div>
          </div>
          <div class="right">
            <img src="../../assets/images/B16.png" class="code"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: "ContactUs",
        components: {},
        props: {},
        data() {
            return {
            }
        },
        computed: {},
        created() {
        },
        mounted() {
           this.$wow.init(); 
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
  .page {
    width: 100%;
    height: 100%;
    background-color: #eff3fb;
    text-align: center;
    padding: 30px 0;

    .top {
      margin-bottom: 50px;

      .title {
        color: #4f85e1;
        font-size: 18px;
      }

      .eng {
        letter-spacing: 5px;
        color: #d1d4d9;
        margin-top: 15px;
        font-size: 12px;
      }
    }
    .content{
      width: 70%;
      margin-left: 15%;
      .map{
        position: relative;
      }
      .img{
        width: 100%;
        height: 500px;
      }
      .map-title{
        bottom: 52%;
        left: 57%;
        position: absolute;
        .shxr-title{
          background-color: rgb(69, 71, 73);
          color: #fff;
          height: 30px;
          border-radius: 3px;
          width: 70px;
          font-size: 12px;
          line-height: 30px;
          text-align: center;
          position: relative;
        }
        .shxr-title::after{
          content: '';
          width: 0;
          height: 0;
          border: 10px solid;
          position: absolute;
          bottom: -58%;
          left: 35%;
          border-color: rgb(69, 71, 73)  transparent transparent;
        }
        .outline{
          color: #4b96f3;
          font-size: 30px;
          margin-top: 10px;
        }
      }
      .bottom{
        background-color: #fff;
        padding: 30px;
        .title{
          text-align: left;
          color: #4f85e1;
          font-weight: 500;
        }
        .line{
          height: 3px;
          width: 40px;
          background-color: #dbdbdc;
          margin-top: 20px;
        }
      }
    }
    .bottom-cell{
      display: flex;
      margin-top: 30px;
    }
    .right{
      display: flex;
      align-items: center;
    }
    .code{
      width: 100px;
      height: 100px;
    }
    .list-cell{
      display: flex;
      flex-wrap: wrap;
      .list{
        display: flex;
        flex: 0 0 50%;
        margin-bottom: 30px;
        .icon{
          display: flex;
          align-items: center;
        }
        .icon-img{
          height: 20px;
          width: 20px;
        }
        .text{
          text-align: left;
          color: #606266;
          font-size: 12px;
          margin-left: 10px;
          .title-address{

          }
          .address{
            margin-top: 10px;
          }
        }
      }
    }
  }
</style>
